// src/axios.js
import axios from 'axios';

// 创建 axios 实例
const instance = axios.create({
  baseURL: 'http://aodaautorepair.store:8081/', // 根据实际情况设置baseURL

  timeout: 10000, // 请求超时时间
});

// 设置请求拦截器，在每次请求中添加 token
instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
  // UjmbdECq

}, error => {
  return Promise.reject(error);
});

// 设置响应拦截器，处理 token 过期或无效
instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('token');  // 移除失效的 token
    window.location.href = '/login';  // 跳转到登录页面
  }
  return Promise.reject(error);
});

export default instance;
